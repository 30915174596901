import { AuthProvider, HttpError } from "@pankod/refine-core";
import { AuthHelper } from "@pankod/refine-strapi-v4";
import axios from "axios";
import { config } from "config";

export const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const customError: HttpError = {
            ...error,
            message: error?.response?.status === 400 ? "Error - check console for details!" : error?.response?.data?.error?.message,
            statusCode: error.response?.status,
        };

        console.error(error?.response?.data?.error?.message)
        console.error("Error details:", JSON.stringify(error?.response?.data?.error?.details?.errors ?? {}))

        return Promise.reject(customError);
    },
);

const TOKEN_KEY = "auth";
const strapiAuthHelper = AuthHelper(config.API_URL + "/api");

export const authProvider: AuthProvider = {
    login: async ({ email, username, password }) => {
        const { data, status } = await strapiAuthHelper.login(
            email ?? username,
            password,
        );
        if (status === 200) {
            localStorage.setItem(TOKEN_KEY, data.jwt);

            // set header axios instance
            axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${data.jwt}`;
            return Promise.resolve("/");
        }
        return Promise.reject();
    },
    logout: () => {
        localStorage.removeItem(TOKEN_KEY);
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            return Promise.resolve();
        }

        return Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (!token) {
            return Promise.reject();
        }

        const { data, status } = await strapiAuthHelper.me(token);
        if (status === 200) {
            const { id, username, email } = data;
            return Promise.resolve({
                id,
                username,
                email,
            });
        }

        return Promise.reject();
    },
};