import {
  AntdLayout,
  Grid,
  Icons,
  Menu,
  Title as DefaultTitle,
} from '@pankod/refine-antd'
import {
  CanAccess,
  ITreeMenu,
  useIsExistAuthentication,
  useLogout,
  useMenu,
  useRefineContext,
  useRouterContext,
  useTitle,
  useTranslate,
} from '@pankod/refine-core'
import { SITE_CONTEXT_PARAM, SITE_NAME } from 'consts'
import { useSiteContextHelper } from 'hooks'
import { CSSProperties, useState } from 'react'

export type SiderRenderProps = {
  items: JSX.Element[]
  logout: React.ReactNode
  dashboard: React.ReactNode
}

export type RefineLayoutSiderProps = {
  render?: (props: SiderRenderProps) => React.ReactNode
}

const { DashboardOutlined, LogoutOutlined, UnorderedListOutlined } = Icons

export const Sidebar: React.FC<RefineLayoutSiderProps> = ({ render }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  const isExistAuthentication = useIsExistAuthentication()
  const { Link } = useRouterContext()
  const { mutate: mutateLogout } = useLogout()
  const Title = useTitle()
  const translate = useTranslate()
  const { menuItems, selectedKey: currentSelectedKey } = useMenu()
  const breakpoint = Grid.useBreakpoint()
  const { hasDashboard } = useRefineContext()
  const { siteSlug, isEnabled } = useSiteContextHelper()
  const isMobile = typeof breakpoint.lg === 'undefined' ? false : !breakpoint.lg
  const selectedKey = currentSelectedKey.replace(SITE_CONTEXT_PARAM, siteSlug)

  const defaultOpenKeys = [`/${siteSlug}`, "/shared"]

  const RenderToTitle = Title ?? DefaultTitle

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {
    return tree.map((item: ITreeMenu) => {
      const { icon, label, route, name, children, parentName } = item

      if (children.length > 0) {
        return (
          <CanAccess
            key={route}
            resource={name.toLowerCase()}
            action="list"
            params={{
              resource: item,
            }}
          >
            <Menu.SubMenu
              key={route}
              icon={icon ?? <UnorderedListOutlined />}
              title={label}
            >
              {renderTreeView(children, selectedKey)}
            </Menu.SubMenu>
          </CanAccess>
        )
      }
      const isSelected = route === selectedKey
      const isRoute = !(parentName !== undefined && children.length === 0)
      return (
        <CanAccess
          key={route}
          resource={name.toLowerCase()}
          action="list"
          params={{
            resource: item,
          }}
        >
          <Menu.Item
            key={route}
            style={{
              fontWeight: isSelected ? 'bold' : 'normal',
            }}
            icon={icon ?? (isRoute && <UnorderedListOutlined />)}
          >
            <Link to={route}>{label}</Link>
            {!collapsed && isSelected && (
              <div className="ant-menu-tree-arrow" />
            )}
          </Menu.Item>
        </CanAccess>
      )
    })
  }

  const normalizeItems = (tree: ITreeMenu[]) => {
    return tree.reduce((acc, item) => {
      if (!isEnabled && item.options?.hiddenIfNoContext) {
        return acc
      }

      const itemCopy = { ...item }

      if (itemCopy.name === SITE_NAME) {
        itemCopy.label = `${siteSlug}`
      }

      itemCopy.route = itemCopy.route?.replace(
        SITE_CONTEXT_PARAM,
        `${siteSlug}`
      )

      itemCopy.children = normalizeItems(itemCopy.children)

      acc.push(itemCopy)

      return acc
    }, [] as ITreeMenu[])
  }

  const logout = isExistAuthentication ? (
    <Menu.Item
      key="logout"
      onClick={() => mutateLogout()}
      icon={<LogoutOutlined />}
    >
      {translate('buttons.logout', 'Logout')}
    </Menu.Item>
  ) : null

  const dashboard = hasDashboard ? (
    <Menu.Item
      key="dashboard"
      style={{
        fontWeight: selectedKey === '/' ? 'bold' : 'normal',
      }}
      icon={<DashboardOutlined />}
    >
      <Link to="/">{translate('dashboard.title', 'Dashboard')}</Link>
      {!collapsed && selectedKey === '/' && (
        <div className="ant-menu-tree-arrow" />
      )}
    </Menu.Item>
  ) : null

  const normalizedItems = normalizeItems(menuItems)
  const items = renderTreeView(normalizedItems, selectedKey)

  const renderSider = () => {
    if (render) {
      return render({
        dashboard,
        items,
        logout,
      })
    }
    return (
      <>
        {dashboard}
        {items}
        {logout}
      </>
    )
  }

  const antLayoutSider: CSSProperties = {
    position: 'relative',
  }
  const antLayoutSiderMobile: CSSProperties = {
    position: 'fixed',
    height: '100vh',
    zIndex: 999,
  }

  return (
    <AntdLayout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      collapsedWidth={isMobile ? 0 : 80}
      width={220}
      breakpoint="lg"
      style={isMobile ? antLayoutSiderMobile : antLayoutSider}
    >
      <RenderToTitle collapsed={collapsed} />
      <Menu
        selectedKeys={[selectedKey]}
        key={JSON.stringify(defaultOpenKeys)}
        defaultOpenKeys={defaultOpenKeys}
        mode="inline"
        onClick={() => {
          if (!breakpoint.lg) {
            setCollapsed(true)
          }
        }}
      >
        {renderSider()}
      </Menu>
    </AntdLayout.Sider>
  )
}
