import {
  Edit,
  Form,
  Typography,
  useForm,
  Image,
  Icons,
  Input,
} from '@pankod/refine-antd'
import { config } from 'config'
import { isMediaImage } from 'helpers'
import { Media } from 'interfaces'

const { Title } = Typography

export const SharedMediaEdit: React.FC = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<Media>({
    redirect: 'show',
  })

  const data = queryResult?.data?.data

  const imageContent = () => (
    <>
      <Image src={config.API_URL! + data?.url} width={200} />
      <Form.Item style={{ marginTop: '20px' }} label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item
        style={{ marginTop: '20px' }}
        label="Alternative text"
        name="alternativeText"
      >
        <Input />
      </Form.Item>
      <Form.Item label="Caption" name="caption">
        <Input />
      </Form.Item>
    </>
  )

  const fileContent = () => (
    <>
      <Icons.FileTwoTone style={{ fontSize: '24px' }} />
      <Form.Item style={{ marginTop: '20px' }} label="Name" name="name">
        <Input />
      </Form.Item>
    </>
  )

  return (
    <Edit
      canDelete
      isLoading={queryResult?.isLoading}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        <Title level={5}>File</Title>
        {isMediaImage(data?.mime) ? imageContent() : fileContent()}
      </Form>
    </Edit>
  )
}
