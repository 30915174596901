import { Button, Form, Input, useForm } from '@pankod/refine-antd'
import { Resources } from 'consts'
import { Media } from 'interfaces'

export interface MediaSelectorEditMediaProps {
  selectedMediaId: number
}

export const MediaSelectorEditMedia: React.FC<MediaSelectorEditMediaProps> = ({
  selectedMediaId,
}) => {
  const { formProps, formLoading } = useForm<Media>({
    resource: Resources.UPLOAD,
    action: 'edit',
    id: selectedMediaId,
    successNotification: {
      message: 'Media has been updated!',
      type: 'success',
    },
    redirect: false,
  })

  return (
    <Form disabled={formLoading} {...formProps} layout="vertical">
      <Form.Item label="Name" name="name" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item label="Alternative text" name="alternativeText">
        <Input />
      </Form.Item>
      <Form.Item label="Caption" name="caption">
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" size="small" htmlType="submit">
          Edit
        </Button>
      </Form.Item>
    </Form>
  )
}
