import {
  Button,
  DeleteButton,
  EditButton,
  Form,
  getDefaultSortOrder,
  Icons,
  Input,
  List,
  ShowButton,
  Space,
  Table,
  useTable,
} from '@pankod/refine-antd'
import { CrudFilters, HttpError } from '@pankod/refine-core'
import { Resources } from 'consts'
import { useSiteContextHelper } from 'hooks'
import { PaymentSystem } from 'interfaces'

export const PaymentSystemsList: React.FC = () => {
  const { siteId, langId, isEnabled } = useSiteContextHelper()

  const { tableProps, sorter, searchFormProps } = useTable<
    PaymentSystem,
    HttpError,
    {
      q: string
    }
  >({
    resource: Resources.PAYMENT_SYSTEMS,
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    permanentFilter: [
      {
        field: 'site][id]',
        operator: 'eq',
        value: siteId,
      },
      {
        field: 'language][id]',
        operator: 'eq',
        value: langId,
      },
    ],
    metaData: {
      populate: ['shared_payment_system'],
    },
    queryOptions: {
      enabled: isEnabled,
    },
    onSearch: (params) => {
      const filters: CrudFilters = []
      let { q } = params

      if (!q) {
        q = ''
      }

      filters.push({
        operator: 'or',
        value: [
          {
            field: 'id',
            operator: 'eq',
            value: q,
          },
          {
            field: '[shared_payment_system][title]',
            operator: 'contains',
            value: q,
          },
          {
            field: 'slug',
            operator: 'contains',
            value: q,
          },
        ],
      })

      return filters
    },
  })

  return (
    <List canCreate title="Payment systems pages">
      <Form {...searchFormProps} layout="inline">
        <Form.Item name="q">
          <Input
            placeholder="ID, title, slug..."
            prefix={<Icons.SearchOutlined />}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="middle" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
      <Table style={{ marginTop: '20px' }} {...tableProps} rowKey="id">
        <Table.Column
          dataIndex={['shared_payment_system', 'title']}
          title="Title"
        />
        <Table.Column<PaymentSystem>
          sorter
          dataIndex="createdAt"
          title="Date"
          defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
          sortDirections={['descend', 'ascend', 'descend']}
          render={(_text, record) => (
            <div>{new Date(record.createdAt).toLocaleDateString('et')}</div>
          )}
        />
        <Table.Column<PaymentSystem>
          title="Actions"
          dataIndex="actions"
          render={(_text, record) => {
            return (
              <Space>
                <ShowButton recordItemId={record.id} hideText />
                <EditButton recordItemId={record.id} hideText />
                <DeleteButton
                  recordItemId={record.id}
                  resourceNameOrRouteName={Resources.PAYMENT_SYSTEMS}
                  hideText
                />
              </Space>
            )
          }}
        />
      </Table>
    </List>
  )
}
