import {
  AuthPage,
  ErrorComponent,
  Icons,
  notificationProvider,
  ReadyPage,
} from '@pankod/refine-antd'
import { handleUseParams, Refine } from '@pankod/refine-core'

import { useLocation, useParams } from 'react-router-dom'

import '@pankod/refine-antd/dist/styles.min.css'
import routerProvider from '@pankod/refine-react-router-v6'
import { DataProvider } from '@pankod/refine-strapi-v4'
import { authProvider, axiosInstance } from 'authProvider'
import { Sidebar } from 'components'
import { config } from 'config'
import {
  Resources,
  SITE_CONTEXT_PARAM,
  SITE_DASHBOARD_ROUTE,
  SITE_NAME,
} from 'consts'
import { SiteProvider } from 'contexts'
import { MainLayout } from 'layouts'
import {
  CasinoNewsList,
  PaymentSystemsCreate,
  PaymentSystemsEdit,
  PaymentSystemsList,
  PaymentSystemsShow,
  ProvidersCreate,
  ProvidersEdit,
  ProvidersList,
  ProvidersShow,
  SharedMediaCreate,
  SharedMediaEdit,
  SharedMediaList,
  SharedMediaShow,
  SharedPaymentSystemsCreate,
  SharedPaymentSystemsEdit,
  SharedPaymentSystemsList,
  SharedPaymentSystemsShow,
  SharedProvidersCreate,
  SharedProvidersEdit,
  SharedProvidersList,
  SharedProvidersShow,
  SitesDashboard,
  SitesList,
} from 'pages'

function App() {
  return (
    <SiteProvider>
      <Refine
        notificationProvider={notificationProvider}
        authProvider={authProvider}
        LoginPage={() => <AuthPage forgotPasswordLink={false} registerLink={false} rememberMe={false} />}
        Layout={MainLayout}
        Sider={Sidebar}
        ReadyPage={ReadyPage}
        catchAll={<ErrorComponent />}
        routerProvider={{
          ...routerProvider,
          useParams: () => {
            const params = useParams()
            const { pathname } = useLocation()

            let paramsString = `/${Object.values(params).join('/')}`

            if (params.context) {
              paramsString = paramsString.replace(`/${params.context}`, '')
            }

            const resource =
              Object.keys(params).length === 0
                ? pathname.substring(1)
                : pathname.substring(1).replace(paramsString, '')

            return handleUseParams({
              ...params,
              resource,
            })
          },
        }}
        dataProvider={DataProvider(`${config.API_URL}/api`, axiosInstance)}
        resources={[
          {
            name: Resources.SITES,
            list: SitesList,
            icon: <Icons.ApartmentOutlined />,
          },
          {
            name: SITE_NAME,
            options: {
              route: SITE_CONTEXT_PARAM,
              hiddenIfNoContext: true,
            },
          },
          {
            name: 'Dashboard',
            parentName: SITE_NAME,
            list: SitesDashboard,
            options: {
              route: SITE_DASHBOARD_ROUTE,
              label: 'Dashboard',
            },
          },
          {
            name: Resources.CASINO_NEWS,
            parentName: SITE_NAME,
            list: CasinoNewsList,
            options: {
              route: Resources.CASINO_NEWS,
            },
          },
          {
            name: Resources.PROVIDERS,
            parentName: SITE_NAME,
            list: ProvidersList,
            create: ProvidersCreate,
            show: ProvidersShow,
            edit: ProvidersEdit,
          },
          {
            name: Resources.PAYMENT_SYSTEMS,
            parentName: SITE_NAME,
            show: PaymentSystemsShow,
            list: PaymentSystemsList,
            create: PaymentSystemsCreate,
            edit: PaymentSystemsEdit,
          },
          {
            name: Resources.UPLOAD,
            icon: <Icons.PictureOutlined />,
            options: {
              label: 'Media',
            },
            list: SharedMediaList,
            show: SharedMediaShow,
            create: SharedMediaCreate,
            edit: SharedMediaEdit,
          },
          {
            name: 'shared',
            icon: <Icons.ShareAltOutlined />,
            options: {
              label: 'Shared',
            },
          },
          {
            name: Resources.SHARED_PAYMENT_SYSTEMS,
            parentName: 'shared',
            options: {
              label: 'Payment Systems',
            },
            list: SharedPaymentSystemsList,
            show: SharedPaymentSystemsShow,
            create: SharedPaymentSystemsCreate,
            edit: SharedPaymentSystemsEdit,
          },
          {
            name: Resources.SHARED_PROVIDERS,
            parentName: 'shared',
            list: SharedProvidersList,
            create: SharedProvidersCreate,
            show: SharedProvidersShow,
            edit: SharedProvidersEdit,
            options: {
              label: 'Providers',
            },
          },
        ]}
      />
    </SiteProvider>
  )
}

export default App
