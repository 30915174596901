import { List, Table, useTable } from '@pankod/refine-antd'
import { Resources } from 'consts'
import { useSiteContextHelper } from 'hooks'
import { CasinoNews } from 'interfaces'

export const CasinoNewsList: React.FC = () => {
  const { siteId, langId, isEnabled } = useSiteContextHelper()

  const { tableProps } = useTable<CasinoNews>({
    resource: Resources.CASINO_NEWS,
    permanentFilter: [
      {
        field: 'site][id]',
        operator: 'eq',
        value: siteId,
      },
      {
        field: 'language][id]',
        operator: 'eq',
        value: langId,
      },
    ],
    queryOptions: {
      enabled: isEnabled,
    },
  })
  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="title" title="Title" />
      </Table>
    </List>
  )
}
