import {
  Button,
  Create,
  Form,
  Image,
  Input,
  useForm,
  useModal,
} from '@pankod/refine-antd'
import { MediaSelectorModal } from 'components'
import { config } from 'config'
import { Media, SharedProvider } from 'interfaces'
import { useEffect, useState } from 'react'
import slug from 'slug'

export const SharedProvidersCreate: React.FC = () => {
  const { formProps, saveButtonProps, form } = useForm<SharedProvider>()

  const [seletectedLogo, setSelectedLogo] = useState<Media | undefined>()

  const { modalProps, show, close } = useModal()
  const titleVal = Form.useWatch('title', form)

  useEffect(() => {
    form.setFieldsValue({
      logo: seletectedLogo?.id,
    })
  }, [seletectedLogo, form])

  useEffect(() => {
    form.setFieldsValue({
      slug: slug(titleVal ?? ''),
    })
  }, [titleVal, form])

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Slug" name="slug" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Logo" required>
          {!seletectedLogo ? (
            <Button onClick={show}>Attach image</Button>
          ) : (
            <Image
              onClick={show}
              preview={false}
              width={200}
              src={`${config.API_URL}${seletectedLogo.url}`}
            />
          )}
          <Form.Item noStyle name="logo" rules={[{ required: true }]}>
            <Input type="hidden" />
          </Form.Item>
        </Form.Item>
      </Form>
      <MediaSelectorModal
        modalProps={{ ...modalProps }}
        onMediaSelect={(media: Media) => {
          setSelectedLogo(media)
          close()
        }}
      />
    </Create>
  )
}
