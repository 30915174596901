import {
  Button,
  RcFile,
  Upload,
  UploadFile,
  UploadProps,
} from '@pankod/refine-antd'
import { useApiUrl } from '@pankod/refine-core'
import { axiosInstance } from 'authProvider'
import { config } from 'config'
import { Media } from 'interfaces'
import { partition } from 'lodash'
import { useCallback, useState } from 'react'
import { MediaUploaderUploadedItem } from './uploaded-item'

export const MediaUploader: React.FC = () => {
  const apiUrl = useApiUrl()

  const [fileList, setFileList] = useState<UploadFile<Media>[]>([])

  const canUpload = fileList.some((f) => f.status === 'success')

  const handleUpload = useCallback(() => {
    let [filesToUpload, otherFiles] = partition(fileList, { status: 'success' })

    filesToUpload = filesToUpload.map((f) => {
      f.status = 'uploading'

      return f
    })

    const promises = []

    for (let f of filesToUpload) {
      const formData = new FormData()
      formData.append('files', f.originFileObj as RcFile)

      const uploadReq = axiosInstance
        .post<Media[]>(`${apiUrl}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((resp) => resp.data[0])

      const promise = async () => {
        const resp = await uploadReq

        const file = { ...f }
        file.status = 'done'
        file.url = config.API_URL + resp.url
        file.response = resp

        return file
      }

      promises.push(promise())
    }

    setFileList([...filesToUpload, ...otherFiles])

    Promise.all(promises).then((values) => {
      let [, otherFiles] = partition(fileList, {
        status: 'uploading',
      })

      setFileList([...values, ...otherFiles])
    })
  }, [apiUrl, fileList])

  const uploadProps: UploadProps = {
    name: 'files',
    multiple: true,
    action: `${apiUrl}/upload`,
    headers: {
      Authorization: axiosInstance.defaults.headers.common[
        'Authorization'
      ] as string,
    },
    listType: 'picture',
    fileList,
    showUploadList: {
      showRemoveIcon: true,
    },
    beforeUpload: () => {
      return false
    },
    onChange: (info) => {
      let newList = [...info.fileList]
        .filter((file) => file.status !== 'done')
        .map((file) => {
          file.status = 'success'

          return file
        })

      setFileList(newList)
    },
    itemRender: (originNode, file) => {
      if (file.status !== 'done') {
        return originNode
      }

      return <MediaUploaderUploadedItem file={file} />
    } 
  }
  return (
    <div>
      <Upload.Dragger {...uploadProps}>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        <p className="ant-upload-hint">Support for a single or bulk upload. </p>
      </Upload.Dragger>
      <Button
        onClick={handleUpload}
        disabled={!canUpload}
        type="primary"
        style={{ marginTop: '20px' }}
      >
        Upload
      </Button>
    </div>
  )
}
