import React, { createContext, useState } from 'react'

export interface SiteContextProps {
  siteId: number | undefined
  langId: number | undefined
  siteTitle: string | undefined
  langCode: string | undefined
  isLoading: boolean
  isError: boolean
}

export type SiteContextType = [
  SiteContextProps,
  React.Dispatch<React.SetStateAction<SiteContextProps>>
]

export const SiteContext = createContext<SiteContextType>(undefined!)
export const initialSiteContext = {
  siteId: undefined,
  langId: undefined,
  siteTitle: undefined,
  langCode: undefined,
  isLoading: true,
  isError: false,
}

export const SiteProvider = (props: any) => {
  const [site, setSite] = useState<SiteContextProps>(initialSiteContext)

  return <SiteContext.Provider value={[site, setSite]} {...props} />
}
