import { Modal, ModalProps } from '@pankod/refine-antd'
import { MediaSelector } from 'components'
import { Media } from 'interfaces'

export interface MediaSelectorModalProps {
  modalProps: ModalProps
  onMediaSelect: (mediaObj: Media) => void
}

export const MediaSelectorModal: React.FC<MediaSelectorModalProps> = ({
  modalProps,
  onMediaSelect,
}) => {
  return (
    <Modal
      {...modalProps}
      width={'80vw'}
      destroyOnClose
      modalRender={() => <MediaSelector onMediaSelect={onMediaSelect} />}
    />
  )
}
