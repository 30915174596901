import { Rule } from "rc-field-form/lib/interface";

export const enum Resources {
    CASINO_NEWS = "casino-news",
    PAYMENT_SYSTEMS = "payment-systems",
    SHARED_PAYMENT_SYSTEMS = "shared-payment-systems",
    SHARED_PROVIDERS = "shared-providers",
    UPLOAD = "upload/files",
    SITES = "sites",
    PROVIDERS = "providers",
}

export const SITE_ROUTE = "site"
export const SITE_NAME = "Site"
export const SITE_DASHBOARD_ROUTE = "dashboard"
export const SITE_CONTEXT_PARAM = ":context"

export const ValidationRules: Record<string, Rule[]> = {
    META_TITLE: [
        {
            required: true,
            type: "string",
            min: 40,
            max: 60
        }
    ],
    META_DESCRIPTION: [
        {
            required: true,
            type: "string",
            min: 70,
            max: 155
        }
    ]

}