import { Alert, AntdLayout } from '@pankod/refine-antd'
import { LayoutProps, useList } from '@pankod/refine-core'
import { Resources } from 'consts'
import { initialSiteContext, SiteContext } from 'contexts'
import { useParams } from 'hooks'
import { Site } from 'interfaces'
import { useContext, useEffect } from 'react'

export const MainLayout: React.FC<LayoutProps> = ({
  Header,
  Footer,
  children,
  Sider,
  OffLayoutArea,
}) => {
  const { context } = useParams()

  const site = context?.split('-')

  const siteTitle = site?.[0]
  const siteLangCode = site?.[1]

  const isQueryEnabled = Boolean(siteTitle) && Boolean(siteLangCode)

  const { data, isLoading, isSuccess, isError } = useList<Site>({
    resource: Resources.SITES,
    metaData: {
      populate: {
        languages: {
          filters: {
            code: {
              $eq: siteLangCode,
            },
          },
        },
      },
    },
    config: {
      filters: [
        {
          operator: 'and',
          value: [
            {
              field: 'title',
              operator: 'eq',
              value: siteTitle,
            },
            {
              field: '[languages][code]',
              operator: 'eq',
              value: siteLangCode,
            },
          ],
        },
      ],
      pagination: {
        pageSize: 1,
      },
    },
    queryOptions: {
      enabled: isQueryEnabled,
    },
  })

  const record = data?.data?.[0]

  const [siteContext, setSiteContext] = useContext(SiteContext)

  useEffect(() => {
    if (!isQueryEnabled) {
      setSiteContext({
        ...initialSiteContext,
      })
    } else if (isError) {
      setSiteContext((state) => ({
        ...state,
        isError: true,
      }))
    } else if (isLoading) {
      setSiteContext((state) => ({
        ...state,
        isLoading: true,
      }))
    } else if (data.total === 0) {
      setSiteContext((state) => {
        return {
          ...state,
          isError: true,
        }
      })
    } else {
      setSiteContext({
        siteId: record?.id,
        siteTitle: record?.title,
        langId: record?.languages[0].id,
        langCode: record?.languages[0].code,
        isLoading: false,
        isError: false,
      })
    }
  }, [
    isError,
    isSuccess,
    isLoading,
    data,
    record,
    setSiteContext,
    isQueryEnabled,
  ])

  const errorNode = () => (
    <Alert
      type="error"
      message="Couldn't find this site or some other issue :("
    />
  )

  return (
    <AntdLayout style={{ minHeight: '100vh', flexDirection: 'row' }}>
      {Sider && <Sider />}
      <AntdLayout>
        {Header && <Header />}
        <AntdLayout.Content>
          {siteContext.isError ? (
            errorNode()
          ) : (
            <div style={{ padding: 24, minHeight: 360 }}>{children}</div>
          )}
        </AntdLayout.Content>
        {Footer && <Footer />}
      </AntdLayout>
      {OffLayoutArea && <OffLayoutArea />}
    </AntdLayout>
  )
}
