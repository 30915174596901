import { Create } from '@pankod/refine-antd'
import { MediaUploader } from 'components'

export const SharedMediaCreate: React.FC = () => {
  return (
    <Create saveButtonProps={{ style: { display: 'none' } }}>
      <MediaUploader />
    </Create>
  )
}
