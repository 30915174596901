import {
  Button,
  CreateButton,
  Form,
  Icons,
  Image,
  Input,
  Show,
  ShowButton,
  Table,
  Typography,
  useTable,
} from '@pankod/refine-antd'
import {
  CrudFilters,
  HttpError,
  useNavigation,
  useShow,
} from '@pankod/refine-core'
import { config } from 'config'
import { Resources } from 'consts'
import { useParams } from 'hooks'
import {
  Provider,
  SharedProvider,
  SiteAndLangWithoutSharedProvider,
} from 'interfaces'

const { Title, Text } = Typography

export const SharedProvidersShow: React.FC = () => {
  const { show, createUrl, push } = useNavigation()
  const params = useParams()

  const { queryResult } = useShow<SharedProvider>({
    metaData: {
      populate: ['logo'],
    },
  })

  const { tableProps: hasPagesTableProps, searchFormProps } = useTable<
    Provider,
    HttpError,
    {
      q: string
    }
  >({
    resource: Resources.PROVIDERS,
    metaData: {
      populate: ['site', 'language'],
    },
    permanentFilter: [
      {
        field: 'shared_provider][id]',
        operator: 'eq',
        value: params.id,
      },
    ],
    queryOptions: {
      enabled: queryResult.isSuccess,
    },
    onSearch(params) {
      const filters: CrudFilters = []
      let { q } = params

      if (!q) {
        q = ''
      }

      filters.push({
        operator: 'or',
        value: [
          {
            field: '[site][id]',
            operator: 'eq',
            value: q,
          },
          {
            field: '[site][title]',
            operator: 'contains',
            value: q,
          },
          {
            field: '[language][code]',
            operator: 'contains',
            value: q,
          },
        ],
      })

      return filters
    },
  })

  const { data, isLoading } = queryResult

  const record = data?.data

  const { tableProps: noPagesTableProps } =
    useTable<SiteAndLangWithoutSharedProvider>({
      resource: `${Resources.SITES}/no-shared-provider/${params.id}`,
      hasPagination: false,
      queryOptions: {
        enabled: queryResult.isSuccess && !!record,
      },
    })

  return (
    <Show isLoading={isLoading} canEdit={true} title={record?.title}>
      <Title level={5}>Slug</Title>
      <Text>{record?.slug}</Text>
      <Title level={5}>Logo</Title>
      {record?.logo ? (
        <Image
          style={{ cursor: 'pointer' }}
          onClick={() => show(Resources.UPLOAD, record?.logo.id)}
          width={200}
          src={`${config.API_URL}${record?.logo.url}`}
          preview={false}
        />
      ) : null}
      <Title style={{ marginTop: '20px' }} level={5}>
        Has page in:
      </Title>
      <Form {...searchFormProps} layout="inline">
        <Form.Item name="q">
          <Input
            placeholder="Site title, lang code..."
            prefix={<Icons.SearchOutlined />}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="middle" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
      <Table style={{ marginTop: '20px' }} {...hasPagesTableProps} rowKey="id">
        <Table.Column<Provider>
          dataIndex="site"
          title="Site"
          render={(_text, record) => {
            return (
              <div>
                {record.site.title}-{record.language.code}
              </div>
            )
          }}
        />
        <Table.Column<Provider>
          dataIndex="actions"
          title="Actions"
          render={(_text, record) => {
            return (
              <ShowButton
                resourceNameOrRouteName={`${record.site.title}-${record.language.code}/${Resources.PROVIDERS}`}
                recordItemId={record.id}
                hideText
              />
            )
          }}
        />
      </Table>
      <Title style={{ marginTop: '20px' }} level={5}>
        No page in:
      </Title>
      <Table style={{ marginTop: '20px' }} {...noPagesTableProps} rowKey="slug">
        <Table.Column<SiteAndLangWithoutSharedProvider>
          dataIndex="site"
          title="Site"
          render={(_text, record) => {
            return <div>{record.slug}</div>
          }}
        />
        <Table.Column<SiteAndLangWithoutSharedProvider>
          dataIndex="actions"
          title="Actions"
          render={(_text, record) => {
            return (
              <CreateButton
                onClick={() => {
                  let url =
                    createUrl(`${record.slug}/${Resources.PROVIDERS}`) +
                    `?${Resources.SHARED_PROVIDERS}=${params.id}`
                  push(url)
                }}
                hideText
              />
            )
          }}
        />
      </Table>
    </Show>
  )
}
