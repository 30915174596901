import {
  Button,
  DeleteButton,
  EditButton,
  Form,
  getDefaultSortOrder,
  Icons,
  Input,
  List,
  ShowButton,
  Space,
  Table,
  Typography,
  useTable,
} from '@pankod/refine-antd'
import { CrudFilters, HttpError } from '@pankod/refine-core'
import { SharedProvider } from 'interfaces'

export const SharedProvidersList: React.FC = () => {
  const { tableProps, sorter, searchFormProps } = useTable<
    SharedProvider,
    HttpError,
    {
      q: string
    }
  >({
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = []
      let { q } = params

      if (!q) {
        q = ''
      }

      filters.push({
        operator: 'or',
        value: [
          {
            field: 'id',
            operator: 'eq',
            value: q,
          },
          {
            field: 'title',
            operator: 'contains',
            value: q,
          },
          {
            field: 'slug',
            operator: 'contains',
            value: q,
          },
        ],
      })

      return filters
    },
  })

  return (
    <List title="Shared Providers" canCreate={true}>
      <div style={{ marginBottom: '20px' }}>
        <Typography.Text>
          Shared resources which are available to be overriden by specific
          site/lang
        </Typography.Text>
      </div>
      <Form {...searchFormProps} layout="inline">
        <Form.Item name="q">
          <Input
            placeholder="ID, title, slug..."
            prefix={<Icons.SearchOutlined />}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="middle" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
      <Table style={{ marginTop: '20px' }} {...tableProps} rowKey="id">
        <Table.Column dataIndex="title" title="Title" />
        <Table.Column<SharedProvider>
          sorter
          dataIndex="createdAt"
          title="Date"
          defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
          sortDirections={['descend', 'ascend', 'descend']}
          render={(_text, record) => (
            <div>{new Date(record.createdAt).toLocaleDateString('et')}</div>
          )}
        />
        <Table.Column<SharedProvider>
          title="Actions"
          dataIndex="actions"
          render={(_text, record): React.ReactNode => (
            <Space>
              <ShowButton size="small" recordItemId={record.id} hideText />
              <EditButton size="small" recordItemId={record.id} hideText />
              <DeleteButton size="small" recordItemId={record.id} hideText />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
