import {
  Button,
  DeleteButton,
  EditButton,
  Form,
  getDefaultSortOrder,
  Icons,
  Image,
  Input,
  List,
  ShowButton,
  Space,
  Table,
  Typography,
  useTable,
} from '@pankod/refine-antd'
import { CrudFilters, HttpError } from '@pankod/refine-core'
import { config } from 'config'
import { isMediaImage } from 'helpers'
import { Media } from 'interfaces'
import React from 'react'

const { Text } = Typography

export const SharedMediaList: React.FC = () => {
  const { tableProps, sorter, searchFormProps } = useTable<
    Media,
    HttpError,
    { q: string }
  >({
    initialSorter: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = []
      let { q } = params

      if (!q) {
        q = ''
      }

      filters.push({
        operator: 'or',
        value: [
          {
            field: 'id',
            operator: 'eq',
            value: q,
          },
          {
            field: 'name',
            operator: 'contains',
            value: q,
          },
          {
            field: 'alternativeText',
            operator: 'contains',
            value: q,
          },
          {
            field: 'caption',
            operator: 'contains',
            value: q,
          },
        ],
      })

      return filters
    },
  })

  return (
    <List canCreate={true}>
      <Form {...searchFormProps} layout="inline">
        <Form.Item name="q">
          <Input
            placeholder="ID, Name, Alt text, etc."
            prefix={<Icons.SearchOutlined />}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" size="middle" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
      <Table {...tableProps} style={{ marginTop: '20px' }} rowKey="id">
        <Table.Column<Media>
          dataIndex="file"
          title="File"
          render={(_text, record): React.ReactNode => (
            <Space>
              {isMediaImage(record.mime) ? (
                <Image
                  src={`${config.API_URL}${record.url}`}
                  width={60}
                  height={50}
                  style={{ objectFit: 'contain' }}
                />
              ) : (
                <Icons.FileTwoTone style={{ fontSize: '16px' }} />
              )}
              <Text>{record.name}</Text>
            </Space>
          )}
        />
        <Table.Column<Media>
          sorter
          dataIndex="createdAt"
          title="Date"
          defaultSortOrder={getDefaultSortOrder('createdAt', sorter)}
          sortDirections={['descend', 'ascend', 'descend']}
          render={(_text, record) => (
            <div>{new Date(record.createdAt).toLocaleDateString('et')}</div>
          )}
        />
        <Table.Column<Media>
          dataIndex="actions"
          title="Actions"
          render={(_text, record) => (
            <Space>
              <ShowButton recordItemId={record.id} hideText />
              <EditButton recordItemId={record.id} hideText />
              <DeleteButton recordItemId={record.id} hideText />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
