import {
  Button,
  Col,
  Image,
  Input,
  Row,
  Show,
  Typography,
} from '@pankod/refine-antd'
import { useNavigation, useRouterContext, useShow } from '@pankod/refine-core'
import { config } from 'config'
import { Resources } from 'consts'
import { useParams } from 'hooks'
import { Provider } from 'interfaces'

const { Title, Text } = Typography

export const ProvidersShow: React.FC = () => {
  const { showUrl } = useNavigation()
  const { Link } = useRouterContext()
  const params = useParams()
  const { queryResult } = useShow<Provider>({
    resource: Resources.PROVIDERS,
    id: params.id,
    metaData: {
      populate: {
        meta: {
          populate: '*',
        },
        shared_provider: {
          populate: ['logo'],
        },
      },
    },
  })
  const { data, isLoading } = queryResult

  const record = data?.data

  return (
    <Show
      isLoading={isLoading}
      canEdit={true}
      title={`${record?.shared_provider.title} Page`}
    >
      <Row gutter={48}>
        <Col span={24} md={12}>
          <Title level={5}>Meta title</Title>
          <Text>{record?.meta.title}</Text>
          <Title level={5}>Meta description</Title>
          <Text>{record?.meta.description}</Text>
          <Title level={5}>Slug</Title>
          <Text>{record?.slug}</Text>
        </Col>
        <Col span={24} md={12}>
          <Title level={4}>Original</Title>
          <Title level={5}>Title</Title>
          <Text>{record?.shared_provider.title}</Text>
          <Title level={5}>Slug</Title>
          <Text>{record?.shared_provider.slug}</Text>
          <Title level={5}>Logo</Title>
          <Image
            width={200}
            src={`${config.API_URL}${record?.shared_provider?.logo?.url}`}
          />
          <div style={{ marginTop: '20px' }}>
            <Link
              to={showUrl(
                Resources.SHARED_PROVIDERS,
                record?.shared_provider.id!
              )}
              target="_blank"
            >
              <Button type="ghost">Go to original</Button>
            </Link>
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: '20px' }}>
        <Col span={24}>
          <Title level={5}>Welcome Block Title</Title>
          <Text>{record?.welcome_block_title}</Text>
          <Title level={5}>Text Block - Welcome</Title>
          <Text>
            <Input.TextArea
              value={record?.welcome_block_text}
              rows={8}
              readOnly
            ></Input.TextArea>
          </Text>
          <Title level={5}>About Block Title</Title>
          <Text>{record?.about_block_title}</Text>
          <Title level={5}>Text Block - About</Title>
          <Text>
            <Input.TextArea
              value={record?.about_block_description}
              rows={8}
              readOnly
            ></Input.TextArea>
          </Text>
        </Col>
      </Row>
    </Show>
  )
}
