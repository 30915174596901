import {
  Create,
  Form,
  Image,
  Input,
  Select,
  Space,
  Typography,
  useForm,
  useSelect,
} from '@pankod/refine-antd'
import { useNavigation, useOne, useResource } from '@pankod/refine-core'
import { config } from 'config'
import { Resources, ValidationRules } from 'consts'
import { useQuery, useSiteContextHelper } from 'hooks'
import { Provider, SharedProvider } from 'interfaces'
import { useEffect } from 'react'

const { Text } = Typography

export const ProvidersCreate: React.FC = () => {
  const { siteId, langId, isEnabled, siteSlug } = useSiteContextHelper()

  const query = useQuery()
  const sharedProviderID = query.get(Resources.SHARED_PROVIDERS)

  const { list } = useNavigation()
  const { resource } = useResource()

  const { formProps, saveButtonProps, form } = useForm<Provider>({
    resource: Resources.PROVIDERS,
    redirect: false,
    onMutationSuccess() {
      list(resource.route)
    },
    queryOptions: {
      enabled: false,
    },
  })

  const { data, isLoading, status } = useOne<SharedProvider>({
    id: sharedProviderID!,
    resource: Resources.SHARED_PROVIDERS,
    metaData: {
      populate: ['logo'],
    },
    queryOptions: {
      enabled: isEnabled && !!sharedProviderID,
    },
  })

  const { selectProps, queryResult } = useSelect<SharedProvider>({
    resource: Resources.SHARED_PROVIDERS,
    metaData: {
      populate: ['logo'],
    },
    sort: [
      {
        field: 'createdAt',
        order: 'desc',
      },
    ],
    queryOptions: {
      enabled: isEnabled && !sharedProviderID,
    },
  })

  const onSharedProviderChange = (value: any) => {
    const val = form.getFieldValue(['shared_provider', 'id'])
    const item = records?.find((r) => r.id === val)

    if (item) {
      form.setFieldsValue({
        slug: `${item.slug}-${siteSlug}`,
      })
    }
  }

  const records = queryResult.data?.data
  const oneRecord = data?.data

  useEffect(() => {
    if (status === 'success' && oneRecord) {
      form.setFieldsValue({
        slug: `${oneRecord.slug}-${siteSlug}`,
      })
    }
  }, [status, form, oneRecord, siteSlug])

  return (
    <Create
      saveButtonProps={saveButtonProps}
      title="Create Provider Page"
      isLoading={!!(isLoading && sharedProviderID)}
    >
      <Form {...formProps} key={`form-${siteId}-${langId}}`} layout="vertical">
        <Form.Item
          label="Meta title"
          name={['meta', 'title']}
          rules={ValidationRules.META_TITLE}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Meta description"
          name={['meta', 'description']}
          rules={ValidationRules.META_DESCRIPTION}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Shared Provider"
          name={['shared_provider', 'id']}
          initialValue={sharedProviderID ? Number(sharedProviderID) : undefined}
          rules={[{ required: true, type: 'number' }]}
        >
          {!sharedProviderID ? (
            <Select
              {...selectProps}
              options={undefined}
              optionLabelProp="label"
              onChange={onSharedProviderChange}
            >
              {records?.map((item) => {
                return (
                  <Select.Option
                    key={item.id}
                    value={item.id}
                    label={item.title}
                  >
                    <Space align="center">
                      <Image
                        width={64}
                        height={64}
                        style={{ objectFit: 'contain' }}
                        src={config.API_URL + item?.logo?.url}
                      />
                      <Text>{item.title}</Text>
                    </Space>
                  </Select.Option>
                )
              })}
            </Select>
          ) : (
            <Space align="center">
              <Image
                width={64}
                height={64}
                style={{ objectFit: 'contain' }}
                src={config.API_URL + oneRecord?.logo?.url!}
              />
              <Text>{oneRecord?.title}</Text>
            </Space>
          )}
        </Form.Item>
        <Form.Item label="Welcome Block Title" name="welcome_block_title">
          <Input />
        </Form.Item>
        <Form.Item label="Text Block - Welcome" name="welcome_block_text">
          <Input.TextArea rows={8} />
        </Form.Item>
        <Form.Item label="About Block Title" name="about_block_title">
          <Input />
        </Form.Item>
        <Form.Item label="Text Block - About" name="about_block_description">
          <Input.TextArea rows={8} />
        </Form.Item>
        <Form.Item label="Slug" name="slug" rules={[{ required: true }]}>
          <Input disabled placeholder="Depends on shared provider" />
        </Form.Item>
        <Form.Item
          name={['site', 'id']}
          rules={[{ required: true, type: 'number' }]}
          initialValue={siteId}
          label="Site id"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name={['language', 'id']}
          rules={[{ required: true, type: 'number' }]}
          initialValue={langId}
          label="Language id"
        >
          <Input disabled />
        </Form.Item>
      </Form>
    </Create>
  )
}
