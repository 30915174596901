import { Edit, Form, Input, useForm } from '@pankod/refine-antd'
import { useNavigation, useResource } from '@pankod/refine-core'
import { Resources, ValidationRules } from 'consts'
import { useParams } from 'hooks'
import { PaymentSystem } from 'interfaces'

export const PaymentSystemsEdit: React.FC = () => {
  const { show } = useNavigation()
  const params = useParams()
  const { resource } = useResource()
  const { formProps, saveButtonProps, queryResult } = useForm<PaymentSystem>({
    id: params.id,
    resource: Resources.PAYMENT_SYSTEMS,
    redirect: false,
    onMutationSuccess: () => {
      show(resource.route, params.id)
    },
    metaData: {
      populate: {
        meta: '*',
        shared_payment_system: {
          populate: ['logo'],
        },
      },
    },
  })

  const record = queryResult?.data?.data

  return (
    <Edit
      canDelete
      deleteButtonProps={{
        resourceNameOrRouteName: Resources.PAYMENT_SYSTEMS,
        recordItemId: params.id,
      }}
      saveButtonProps={saveButtonProps}
      title={`Edit ${record?.shared_payment_system.title} Page`}
      isLoading={queryResult?.isLoading}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Meta title"
          name={['meta', 'title']}
          rules={ValidationRules.META_TITLE}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Meta description"
          name={['meta', 'description']}
          rules={ValidationRules.META_DESCRIPTION}
        >
          <Input />
        </Form.Item>
      </Form>
    </Edit>
  )
}
