import { List, Table, useTable } from '@pankod/refine-antd'
import { Site } from 'interfaces'

import RouterProvider from '@pankod/refine-react-router-v6'
import { SITE_DASHBOARD_ROUTE } from 'consts'

const { Link } = RouterProvider

export const SitesList: React.FC = () => {
  const { tableProps, tableQueryResult } = useTable<Site>({
    metaData: {
      populate: {
        languages: '*',
      },
    },
  })

  const expandedRowRender = (site: Site) => {
    return (
      <div>
        {site.languages.map((lang) => {
          return (
            <div key={lang.id}>
              <Link to={`/${site.title}-${lang.code}/${SITE_DASHBOARD_ROUTE}`}>
                {lang.code}
              </Link>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <List>
      <Table
        {...tableProps}
        key={String(tableQueryResult.isLoading)}
        rowKey="id"
        expandable={{
          expandedRowRender,
          defaultExpandAllRows: true,
        }}
      >
        <Table.Column dataIndex="title" title="Site" />
      </Table>
    </List>
  )
}
