import { SiteContext } from "contexts"
import { useContext } from "react"

export const useSiteContextHelper = () => {
    const [siteContext] = useContext(SiteContext)

    return {
        ...siteContext,
        isEnabled: Boolean(siteContext.siteId) && Boolean(siteContext.langId),
        siteSlug: `${siteContext.siteTitle}-${siteContext.langCode}`
    }
}