import { Icons, Image, Show, Typography } from '@pankod/refine-antd'
import { useShow } from '@pankod/refine-core'
import { config } from 'config'
import { isMediaImage } from 'helpers'
import { Media } from 'interfaces'

const { Title, Text } = Typography

export const SharedMediaShow: React.FC = () => {
  const { queryResult } = useShow<Media>()

  const data = queryResult.data?.data

  const imageContent = () => (
    <>
      <Image src={config.API_URL! + data?.url} width={200} />
      <Title level={5} style={{ marginTop: '20px' }}>
        Name
      </Title>
      <Text>{data?.name}</Text>
      <Title level={5}>Alternative text</Title>
      <Text>{data?.alternativeText}</Text>
      <Title level={5}>Caption</Title>
      <Text>{data?.caption}</Text>
      <Title level={5}>Dimensions</Title>
      <Text>{`${data?.width}x${data?.height}`}</Text>
    </>
  )

  const fileContent = () => (
    <>
      <Icons.FileTwoTone style={{ fontSize: '24px' }} />
      <Title level={5} style={{ marginTop: '20px' }}>
        Name
      </Title>
      <Text>{data?.name}</Text>
    </>
  )

  return (
    <Show isLoading={queryResult.isLoading}>
      <Title level={5}>File</Title>
      {isMediaImage(data?.mime) ? imageContent() : fileContent()}
    </Show>
  )
}
