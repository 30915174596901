import { Show } from '@pankod/refine-antd'
import { useSiteContextHelper } from 'hooks'

export const SitesDashboard: React.FC = () => {
  const { siteSlug, isLoading } = useSiteContextHelper()

  return (
    <Show headerButtonProps={{ hidden: true }} isLoading={isLoading}>
      <div>{siteSlug}</div>
    </Show>
  )
}
