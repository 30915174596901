import {
  Button,
  Form,
  Icons,
  Input,
  Modal,
  UploadFile,
  useModalForm,
} from '@pankod/refine-antd'
import { Resources } from 'consts'
import { isMediaImage } from 'helpers'
import { Media } from 'interfaces'

export interface MediaUploaderUploadedItemProps {
  file: UploadFile<Media>
}

export const MediaUploaderUploadedItem: React.FC<
  MediaUploaderUploadedItemProps
> = ({ file }) => {
  const { formProps, modalProps, show, formLoading } = useModalForm({
    resource: Resources.UPLOAD,
    action: 'edit',
    redirect: false,
    autoSubmitClose: true,
    successNotification: {
      message: 'Media has been updated!',
      type: 'success',
    },
  })

  return (
    <>
      <div className="ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture">
        <div className="ant-upload-list-item-info">
          <span className="ant-upload-span">
            <a
              className="ant-upload-list-item-thumbnail"
              href={file.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {isMediaImage(file.response?.mime) ? (
                <img
                  style={{ objectFit: 'contain' }}
                  src={file.url}
                  alt={file.fileName}
                  className="ant-upload-list-item-image"
                />
              ) : (
                <Icons.PictureTwoTone />
              )}
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="ant-upload-list-item-name"
              title={file.name}
              href={file.url}
              onClick={(e) => {
                e.preventDefault()
                show(file.response?.id)
              }}
            >
              {file.name}
            </a>
            <span className="ant-upload-list-item-card-actions picture">
              <Button
                onClick={() => show(file.response?.id)}
                type="text"
                icon={<Icons.EditTwoTone />}
              />
            </span>
          </span>
        </div>
      </div>
      <Modal {...modalProps} title="Edit media">
        <Form {...formProps} disabled={formLoading} layout="vertical">
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Alternative text" name="alternativeText">
            <Input />
          </Form.Item>
          <Form.Item label="Caption" name="caption">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
