import {
  Button,
  Col,
  Divider,
  Form,
  Icons,
  Image,
  Input,
  Pagination,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
} from '@pankod/refine-antd'
import { useList } from '@pankod/refine-core'
import { config } from 'config'
import { Media } from 'interfaces'
import { useEffect, useState } from 'react'
import styles from './media-selector.module.css'

import cn from 'classnames'
import { MediaUploader } from 'components'
import { Resources } from 'consts'
import { MediaSelectorEditMedia } from './edit-media'

export interface MediaSelectorProps {
  onMediaSelect: (media: Media) => void
}

export const MediaSelector: React.FC<MediaSelectorProps> = ({
  onMediaSelect,
}) => {
  const [current, setCurrent] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(28)
  const [searchVal, setSearchVal] = useState<string>('')

  const {
    data,
    isLoading,
    refetch: refetchMedia,
  } = useList<Media>({
    resource: Resources.UPLOAD,
    config: {
      pagination: {
        current: current,
        pageSize: pageSize,
      },
      sort: [
        {
          field: 'createdAt',
          order: 'desc',
        },
      ],
      filters: [
        {
          field: 'mime',
          operator: 'contains',
          value: 'image',
        },
        {
          operator: 'or',
          value: [
            {
              field: 'id',
              operator: 'eq',
              value: searchVal,
            },
            {
              field: 'name',
              operator: 'contains',
              value: searchVal,
            },
            {
              field: 'alternativeText',
              operator: 'contains',
              value: searchVal,
            },
            {
              field: 'caption',
              operator: 'contains',
              value: searchVal,
            },
          ],
        },
      ],
    },
  })

  const [selectedMedia, setSelectedMedia] = useState<Media | undefined>()

  useEffect(() => {
    setSelectedMedia(undefined)
  }, [current, pageSize, searchVal])

  const records = data?.data

  return (
    <div className={styles.wrapper}>
      <Tabs
        onChange={() => {
          setCurrent(1)
          setSelectedMedia(undefined)
          setSearchVal('')
          refetchMedia()
        }}
        destroyInactiveTabPane={true}
        defaultActiveKey="select-media"
      >
        <Tabs.TabPane tab="Upload media" key="upload-media">
          <MediaUploader />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Select media" key="select-media">
          <Form
            layout="inline"
            style={{ marginBottom: '20px' }}
            onFinish={(values) => {
              setCurrent(1)
              setSearchVal(values.q)
              setSelectedMedia(undefined)
            }}
          >
            <Form.Item name="q">
              <Input
                placeholder="ID, Name, Alt text, etc."
                prefix={<Icons.SearchOutlined />}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" size="middle" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </Form>
          {isLoading ? (
            <Spin
              style={{ display: 'flex', justifyContent: 'center' }}
              size="large"
            />
          ) : (
            <>
              <Row style={{ minHeight: 400 }}>
                <Col span={18}>
                  <Space wrap>
                    {records?.map((record) => {
                      return (
                        <div
                          key={record.id}
                          className={cn(styles.imageWrapper, {
                            [styles.selected]: selectedMedia?.id === record.id,
                          })}
                          onClick={() =>
                            selectedMedia?.id === record.id
                              ? setSelectedMedia(undefined)
                              : setSelectedMedia(record)
                          }
                        >
                          <Image
                            width={100}
                            height={100}
                            style={{ objectFit: 'contain' }}
                            preview={false}
                            src={`${config.API_URL}${record.url}`}
                          />
                        </div>
                      )
                    })}
                  </Space>
                  <div className={styles.pagination}>
                    <Pagination
                      onChange={setCurrent}
                      onShowSizeChange={(current, pageSize) => {
                        setCurrent(current)
                        setPageSize(pageSize)
                      }}
                      current={current}
                      pageSize={pageSize}
                      total={data?.total}
                    />
                  </div>
                </Col>
                <Col span={6}>
                  {!selectedMedia ? (
                    <Typography.Title level={4}>
                      Select item to edit
                    </Typography.Title>
                  ) : (
                    <>
                      <Typography.Title level={4}>Edit Media</Typography.Title>
                      <MediaSelectorEditMedia
                        selectedMediaId={selectedMedia.id}
                      />
                    </>
                  )}
                </Col>
              </Row>
            </>
          )}
          <Divider />
          <div className={styles.actions}>
            <Button
              type="primary"
              disabled={selectedMedia === undefined}
              onClick={() => {
                onMediaSelect(selectedMedia!)
                setSelectedMedia(undefined)
              }}
            >
              Attach
            </Button>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}
